.imageCardContainer {
    display: flex;
    flex-wrap: wrap;
    margin: auto;
    gap: 20px;
    width: 100%;
    max-width: 1164px;
    justify-content: space-between;
    margin: 120px 20px 40px;
    @media (max-width: 767px) {
        gap: 10px;
        margin: 90px 15px 20px;
        width: 95%;
    }
    .cardImage {
        width: 46%;
        @media (max-width: 767px) {
            width: 95%;
            margin: auto;
        }
    }
    .imageCardContent {
        width: 46%;
        @media (max-width: 767px) {
            width: 90%;
            margin: 20px auto;
        }
        .cardHeading {
            font-size: 2.5rem;
            font-weight: 800;
            line-height: 1.2;
            margin-bottom: 10px;
            @media (max-width: 767px) {
                font-size: 1.4rem;
                line-height: 1;
            }
        }
        .cardDescription {
            font-size: 1.5rem;
            line-height: 1.2;
            margin-bottom: 24px;
            @media (max-width: 767px) {
                font-size: 0.8rem;
                line-height: 1;
            }
        }
        .cardBtn{
            padding: 10px 25px;     
            background: #185ADB;
            border-radius: 4px;
            font-weight: 600;
            font-size: 16px;
            text-align: center;
            color: #FFFFFF;
            margin-top: 20px;
            display: flex;
            width: 32%;
            justify-content: center;
            height: 50px;
            align-items: center;
            @media (max-width: 768px) {
                font-size: 12px;
                width: 50%;
                height: 42px;
            }
        }
        .cardBtn:hover{
            background: #185ADB;
        }
    }
}