.modal-wrapper {
    .top-wrapper {
        display: flex;
        justify-content: space-between;
        gap: 20px;
        margin-bottom: 30px;
        .store-images-container {
            width: 100%;
            max-width: 400px;
            .store-image {
                width: 100%;
            }
        }
        .store-details-wrapper {
            width: 100%;
            max-width: 555px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .top-details {
                .store-title {
                    font-size: 20px;
                    font-weight: 600;
                    word-break: break-all;
                    display: block;
                    display: -webkit-box !important;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 1;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    margin-bottom: 10px;
                }
                .store-rating-wrapper {
                    margin-bottom: 10px;
                    display: flex;
                    gap: 20px;
                    .store-rating {
                        display: flex;
                        gap: 2px;
                    }
                    .seller-name {
                        span {
                            font-weight: 500;
                            text-decoration: underline;
                        }
                    }
                }
                .price-container {
                    font-size: 40px;
                    font-weight: 800;
                    color: var(--p-color-primary)
                }
                .origin {
                    span {
                        font-weight: 700;
                    }
                }
                .last-checked {
                    font-size: 10px;
                    text-align: right;
                    margin-top: 10px;
                }
            }
            .sales-stats {
                display: flex;
                gap: 20px;
                .sales-card {
                    width: 200px;
                    height: 100px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-color: rgb(233 233 233);
                    border-radius: 10px;
                    flex-direction: column;
                    font-size: 20px;
                    font-weight: 700;
                    span {
                        font-size: 14px;
                        font-weight: 400;
                    }
                }
            }
        }
    }
    .store-trends-charts {
        width: 100%;
        .price-reviews-trends {
            width: 100%;
            flex-wrap: wrap;
        }
    }
}
.skeleton-store-detail {
    .skeleton-container {
        justify-content: start;
        padding: 0px 0px 10px;
        border-radius: 10px !important;
        gap: 18px !important;
        .skeleton-image {
            width: 500px !important;
            height: 400px !important;
        }
        .skeleton-detail-container {
            display: flex;
            flex-direction: column;
            width: 434px;
            height: 400px;
            align-items: start;
            .title-skeleton {
                width: 408px;
            }
            .price-container {
                margin-bottom: 40px;
            }
            .rating-skeleton {
                display: flex;
            }
            .stats-container {
                width: 408px;
                display: flex;
                justify-content: space-between;
                margin-top: 10px;
            }
        }
    }
}