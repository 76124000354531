.uninstall-feedback-form {
    max-width: 500px;
    margin: 0 auto;
    padding: 20px;
    border-radius: 5px;
    background-color: #fff;
    box-shadow: 1px 6px 20px -3px #0000003d;
    text-align: left;
    .form-group {
        margin-bottom: 15px;

        label {
            display: block;
            margin-bottom: 5px;
        }

        .checkbox-group {
            display: flex;
            flex-direction: column;

        label {
            margin-bottom: 5px;
            display: flex;
            align-items: center;
            margin-top: 15px;
            input {
                margin-right: 10px;
                width: 17px;
                height: 17px;
            }
        }
        }

        textarea {
            width: 100%;
            padding: 8px;
            border: 1px solid #ccc;
            border-radius: 4px;
            box-sizing: border-box;
        }

        input[type="text"],
        textarea {
            width: 100%;
            height: 50px;
            font-size: var(--input-font-size);
            padding-inline-start: var(--input-padding);
            padding-inline-end: var(--input-padding);
            min-width: 0px;
            outline: transparent solid 2px;
            outline-offset: 2px;
            position: relative;
            appearance: none;
            border-radius: 6px;
            padding-left: 20px;
            border: 1px solid rgb(205, 205, 205);
            background-color: rgb(255, 255, 255);
        }
    }

    button {
        width: 30%;
        margin: auto;
        padding: 10px;
        background-color: var(--p-color-primary);
        color: #fff;
        border: none;
        border-radius: 4px;
        font-size: 16px;
        cursor: pointer;
        transition: background-color 0.3s;

        &:hover {
        background-color: #218838;
        }
    }
}
