.news-strip{
    justify-content: center;
    height: 40px;
    width: -webkit-fill-available;
    align-items: center;
    gap: 5px;
    position: fixed;
    z-index: 10;

    .beta-text {
        font-size: 14px;
        color: white;
        margin-bottom: 0px;
        .text-main {
            font-weight: bold;
            margin-right: 5px;
        }
        .beta-header {
            background-color: #0A1931;
            border-radius: 4px;
            padding: 0px 8px;
            gap: 6px;
            font-size: 12px;
            font-weight: bold;
        }
    }

    .text{
        font-size: 15px;
        font-weight: 600;
        color: #fff;
        margin: 0;
        @media (max-width: 767px) {
            font-size: 12px;
        }
    }
    .news-btn{
        cursor: pointer;
        border: 1px solid #fff;
        justify-content: center;
        align-items: center;
        padding: 5px 20px;
        border-radius: 2px;
        margin-left: 10px;
        @media (max-width: 767px) {
            padding: 3px 10px;
        }
        .btn-text{
            padding: 0;
            margin: 0;
            font-size: 13px;
            font-weight: 400;
            color: #fff;
        }
    }

    .close-icon {
        position: absolute;
        right: 1rem;
        top: 12px;
        color: #fff;

        &:hover {
            cursor: pointer;
        }
    }
}