.subscribe-text{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 34px;
    line-height: 140%;
    text-align: center;
    text-transform: capitalize;
    color: #FFFFFF;
    width: 60%;
    @media (max-width: 868px) {
        font-size: 24px;
        width: 90%;
      }
      @media (max-width: 600px) {
        font-size: 20px;
        width: 90%;
      }
}
.subscribe-des{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 180%;
    color: #FFFFFF;
    opacity: 0.8;
    @media (max-width: 868px) {
        font-size: 16px;
        text-align: center;
      }
      @media (max-width: 600px) {
        font-size: 14px;
        text-align: center;
      }
}
.box{
    width: 474px;
    height: 65px;
    background: #FFFFFF;
    border-radius: 10px;
    align-items: center;
    justify-content: space-around;
    @media (max-width: 768px) {
    height: 55px;
        width: 90%;
        border-radius: 8px;
        padding-left: 10px;
        justify-content: space-between;
      }
}
.btn{
    width: 160px;
    height: 48px;
    background: #185ADB !important;
    border-radius: 7px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 150%;
    color: #FFFFFF !important;
    @media (max-width: 768px) {
        font-size: 12px !important;
        width: 100px;
        height: 42px;
        border-radius: 10px;
        margin-right: 5px;
      }
    
}
.btn:hover{
    background: #185ADB;
    color: #FFFFFF;
}