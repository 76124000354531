
.inner{
    gap: 40px;
    display: flex;
    @media (max-width: 768px) {
        display: none;
      }
}
.link-text{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
    color: #0A1931;
    cursor: pointer;
}