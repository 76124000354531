.footer-container {
  margin-top: 275px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 767px) {
    margin-top: 125px;
  }
  .footer-content {
    background-color: #0a1931;
    width: 100%;
    padding-bottom: 20px;

    .footer-main {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: flex-start;
      padding: 40px 10%;
      gap: 20px;
      margin-top: 174px;
      .footer-left {
        flex: 1;

        .footer-logo {
          max-width: 150px;
        }

        .footer-text {
          margin: 20px 0;
          color: #ffffff;
          font-size: 14px;
        }

        .footer-social-links {
          display: flex;
          gap: 15px;

          .social-icon {
            width: 35px;
            height: 35px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            background-color: #185adb;
            color: #ffffff;
            text-decoration: none;

            &:hover {
              background-color: #132a7a;
              color: #185adb;
            }
          }
        }
      }

      .footer-right {
        flex: 1;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        gap: 30px;

        .footer-links-section {
          flex: 1;

          .footer-links-title {
            color: #185adb;
            font-size: 16px;
            font-weight: 600;
          }

          .footer-links {
            list-style: none;
            padding: 0;
            margin: 10px 0 0;

            .footer-link {
              text-decoration: none;
              color: #ffffff;
              font-size: 14px;

              &:hover {
                color: #185adb;
              }
            }
          }
        }
      }
    }

    .footer-copy {
      text-align: center;
      margin-top: 20px;
      color: #185adb;
      font-size: 14px;
    }
  }
}

.subscribe-text{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 34px;
    line-height: 140%;
    text-align: center;
    text-transform: capitalize;
    color: #FFFFFF;
    width: 60%;
    @media (max-width: 868px) {
        font-size: 24px;
        width: 90%;
      }
      @media (max-width: 600px) {
        font-size: 20px;
        width: 90%;
      }
}
.subscribe-des{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 180%;
    color: #FFFFFF;
    opacity: 0.8;
    @media (max-width: 868px) {
        font-size: 16px;
        text-align: center;
      }
      @media (max-width: 600px) {
        font-size: 14px;
        text-align: center;
      }
}
.box{
    width: 474px;
    height: 65px;
    background: #FFFFFF;
    border-radius: 10px;
    align-items: center;
    justify-content: space-around;
    @media (max-width: 768px) {
    height: 55px;
        width: 90%;
        border-radius: 8px;
        padding-left: 10px;
        justify-content: space-between;
      }
}
.btn{
    width: 160px;
    height: 48px;
    background: #185ADB !important;
    border-radius: 7px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 150%;
    color: #FFFFFF !important;
    @media (max-width: 768px) {
        font-size: 12px !important;
        width: 100px;
        height: 42px;
        border-radius: 10px;
        margin-right: 5px;
      }
    
}
.btn:hover{
    background: #185ADB;
    color: #FFFFFF;
}
.footer-text{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 180%;
    color: #fff;
    width: 65%;
    @media (max-width: 768px) {
         width: 100%;
      }
}
.circles{
    height: 34px;
    width: 34px;
    align-items: center;
    justify-content: center;
    background: #185ADB;
    border-radius: 100%;
    cursor: pointer;
}
.circles:hover{
    background: #03215d;
}

.footer_link {
  font-size: 14px;
  color: #fff;
}