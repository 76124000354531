// src/MaintenanceMode.scss
.maintenance-mode {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
  background-color: #f4f4f4;
  text-align: center;

  .maintenance-container {
    height: 100%;
    background-color: #fff;
    padding: 40px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .maintenance-icon {
      color: #0e1c44;
      margin-bottom: 20px;
      font-size: 70px;
    }
    h1 {
      margin-bottom: 20px;
      font-size: 2.5em;
      color: #333;
    }

    p {
      font-size: 1.2em;
      color: #666;
    }
  }
}
