.mainContainer {
    display: grid;
    grid-template-columns: var(--nav-width) 1fr;
    grid-template-rows: var(--app-height) 1fr;
    grid-template-areas:
        "nav-rail app-bar"
        "nav-rail content";
    height: 100vh;
    width: 100%;
    --app-index: 299;
    --nav-index: 299;
    --app-height: 70px;
    --nav-width: 290px;
    max-width: 100vw;
    overflow-y: hidden;
    .content-container {
        grid-area: content;
        max-width: calc(100vw - var(--nav-width));
        overflow-y: auto;
        background-color: #f3f3f3;
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 100%;
    }
    .beta {
        padding: 4px 10px;
        font-size: 11px;
        background-color: #00b3d4;
        border-radius: 15px;
    }
    @media (max-width: 1140px) {
        
        --nav-width: 0px;
    }
}
