.store-card {
    box-shadow: rgba(0, 0, 0, 0.15) 0px 1px 2px 0px, rgba(0, 0, 0, 0.05) 0px 0px 5px 0px;
    transition: all 0.25s ease-in-out;
    border-radius: 0.7rem;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    display: flex;
    border-radius: 12px;
    &:hover::after {
        content: "";
        position: absolute;
        border-radius: 12px;
        z-index: -1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        box-shadow: rgba(0, 0, 0, 0.25) 0px 5px 15px;
        opacity: 0;
        transition: opacity 0.24s ease-in-out;
    }

    .image-wrapper {
        border-top-left-radius: 0.5rem;
        border-top-right-radius: 0.5rem;
        height: 120px;
        width: 120px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;
        border-radius: 0 0 0.5rem 0.5rem;
        .store-image {
            border-top-left-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;
        }
        &::after {
            content: "";
            inset: 0;
            position: absolute;
            padding-bottom: 100%;
            display: block;
            border-radius: var(--p-border-radius-300);
        }
    }
    .store-details {
        padding: 16px 16px 12px;
        background: #fff;
        border-radius: 0 12px 12px 0px;
        display: flex;
        gap: 10px;
        width: 92%;
        justify-content: space-between;
        .store-details-content {
            display: flex;
            justify-content: space-between;
            width: 100%;
            .store-title {
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                font-size: 18px;
                font-weight: 600;
            }
            .price-container {
                font-weight: 600;
                display: flex;
                justify-content: space-between;
                .saling-price {
                    font-size: 18px;
                    font-weight: 400;
                    display: flex;
                    flex-direction: column;
                    span {
                        font-size: 10px;
                    }
                }
                .stat-item {
                    display: flex;
                    gap: 5px;
                    font-size: 14px;
                    font-weight: 100;
                }
            }
            .last-checked {
                font-size: 10px;
                text-align: right;
                margin-top: 10px;
            }
            .sold-units-container {
                min-width: 230px;
                .sold-units {
                    font-size: 12px;
                    font-weight: 600;
                }
                .stats-container {
                    display: flex;
                    gap: 20px;
                    .stat-item {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        font-size: 13px;
                        flex-direction: column;
                        width: 50%;
                        .sale-count {
                            font-size: 18px;
                        }
                        .growth-rate {
                            display: flex;
                            align-items: center;
                            gap: 2px;
                            .growth-down {
                                color: rgb(240, 73, 73)
                            }
                            .growth-up {
                                color: rgb(10, 189, 100)
                            }
                        }
                    }
                    .flex {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                    }
                }
            }
            
        }
        .store-quick-view-wrap {
            padding: 0 16px;
            background: #fff;
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            .action-btns {
                width: 100%;
            }
        }
    }
    &:hover {
        transform: scale(1.02) translate3d(0, 0, 0);
        * {
            box-sizing: border-box;
            transition: all 0.4s ease 0s;
        }
    }

    .primary-btn {
        padding: 5px 15px;
        width: 100%;
        height: 38px;
        border-radius: 10px;
        background-color: var(--p-color-primary);
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 5px;
        &:hover {
            background-color: var(--p-color-primary);
            color: #fff !important;
        }
    }
    .secondary-btn {
        padding: 5px 15px;
        width: 100%;
        height: 38px;
        border-radius: 10px;
        margin-top: 10px;
    }
}

.locked-store-card {
    .overlay {
        z-index: 1;
        position: absolute;
        display: block;
        width: 100%;
        height: 100%;
        background: rgba(255, 255, 255, 0.8);
        backdrop-filter: blur(6px);
    }
    .upgrade-container {
        font-size: 14px;
        position: absolute;
        display: flex;
        flex-direction: column;
        gap: 20px;
        z-index: 2;
        top: 30%;
        padding: 32px;
        text-align: center;
    }
}