.whatsappReward {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    text-align: center;
    .rewardIcon {
        color: #185ADB;
    }
    .rewardTitle {
        font-size: 27px;
        font-weight: 800;
        margin-bottom: 20px;
    }
    .rewardDetails{ 
        font-size: 17px;
    }
}
