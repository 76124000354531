.skeleton-card {
    width: 100%;
    .ant-card-body {
        padding: 0px !important;
        .ant-space-item {
            width: 100%;
        }
        .skeleton-container {
            flex-direction: column;
            justify-content: start;
            padding: 0px 0px 10px;
            border-radius: 10px !important;
            box-shadow: rgba(0, 0, 0, 0.15) 0px 1px 2px 0px, rgba(0, 0, 0, 0.05) 0px 0px 5px 0px;
            .skeleton-image {
                width: 271px !important;
                height: 260px !important;
                border-radius: 10px 10px 0px 0px ;
            }
            .price-skeleton {
                width: 100%;
                padding: 0px 10px;
            }
        }
    }
    
}