.terms-container {
    width: 70vw;
    @media (max-width: 420px) {
        width: 95%;
    }
    p {
        font-size: 15px;
        margin-bottom: 20px;
        margin-top: 10px;
    }
    h3 {
        font-weight: 700;
        font-size: 20px;
    }
    ul, ol {
        margin-left: 18px;
        margin-top: 20px;
        margin-bottom: 20px;
    }
}
.terms-heading {
    margin: 140px 0px 40px;
    font-size: 30px;
    font-weight: 800;
}