.store-discovery-container {
    margin-left: 0px;
    margin-right: auto;
    width: 85%;
    @media (max-width: 767px) {
        width: 100%;
    }
    .search-input-container {
        position: relative;
        svg {
            position: absolute;
            left: 10px;
            top: 11px;
            color: var(--p-color-icon-secondary)
        }
        .input {
            padding: .375rem .75rem 0.375rem 2rem !important;
        }
    }
    .filter-label {
        font-size: 14px;
        padding-bottom: 2px;
    }

    .input-container {
        margin-bottom: 15px;
        @media (max-width: 767px) {
            width: 100%;
        }
        &:not(:nth-child(3), .search-input, .action-btns) {
            margin-right: 40px;
            @media (max-width: 767px) {
                margin-right: 0px;
            }
        }
        .input {
            font-size: 0.8rem;
            font-weight: 400;
            line-height: 1.5rem;
            letter-spacing: initial;
            position: relative;
            display: flex;
            flex: 1 1;
            width: 100%;
            min-width: 0;
            min-height: 2rem;
            padding: .375rem .75rem;
            background: none;
            border: none;
            -webkit-appearance: none;
            appearance: none;
            caret-color: var(--p-color-text);
            color: var(--p-color-text);
            align-items: center;
            border: 1px solid var(--p-color-border);
            border-radius: 10px;
            background-color: #fff;
        }
        .input-group {
            display: flex;
            align-items: center;
            gap: 8px;
            max-width: 270px;
            @media (max-width: 767px) {
                max-width: 100%;
            }
        }
        .search-btn {
            padding: 5px 15px;
            width: 150px;
            height: 38px;
            font-size: 14px;
            border-radius: 10px;
            background-color: var(--p-color-primary);
            color: #fff;
            
            &:hover {
                color: #fff;
            }
        }
        .secondary-btn {
            padding: 5px 15px;
            width: 150px;
            height: 38px;
            border-radius: 10px;
            margin-left: 20px;
        }
        .select-input {
            width: 220px;
            .ant-select-selector {
                height: 38px;
                overflow-y: scroll;
                border: 1px solid var(--p-color-border) !important;
                border-radius: 10px !important;
                .ant-select-selection-placeholder {
                    color: var(--p-color-grey);
                }
                .ant-select-selection-item {
                    margin-top: 3px;
                }
            }
            .ant-select-arrow {
                color: var(--p-color-grey) !important;
            }
        }
    }
    .action-btns {
        margin-top: 10px;
        @media (max-width: 767px) {
            display: flex;
            gap: 20px;
        }
        button {
            @media (max-width: 767px) {
                margin-left: 0px !important;
            }
            @media (max-width: 767px) {
                width: 50% !important;
            }
        }
    }
    .min-max-filters {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        margin-right: 20px;
        justify-content: space-between;
        align-items: flex-end;
    }
    
}
