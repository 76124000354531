@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --p-color-text: #282c34;
  --p-color-border: rgba(138, 138, 138, 1);
  --p-color-icon-secondary: rgba(138, 138, 138, 1);
  --p-color-primary: #185ADB;
  --p-border-radius-300: .75rem;
  --p-color-grey: #718096
}

@font-face {
  font-family: 'Inter';
  src: url('assets/Inter/static/Inter-Regular.ttf') format('woff2');
}

@font-face {
  font-family: 'Poppins';
  src: url('assets/Poppins/Poppins-Regular.ttf') format('woff2');
}

@font-face {
  font-family: 'Space Grotesk';
  src: url('assets/Space_Grotesk/static/SpaceGrotesk-Regular.ttf') format('woff2');
}

body {
  margin: 0;
  font-family: "Inter", "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.otp-input {
  width: 3rem !important;
  height: 3rem;
  margin: 0 0.5rem 0 0;
  font-size: 1.3rem;
  border-radius: 5px;
  border: 1px solid #ccc;
  background-color: #fff;
  text-align: center;
  @media (max-width: 470px) {
    width: 2rem !important;
    height: 2rem;
    margin: 0 7px 0 0;
  }
}
.btnPrimary {
  background-color: #185ADB;
  color: #fff;
  border-radius: 5px;
  padding: 10px 20px;
}
.btnSecondary {
  background-color: #ffffffb5 !important;
  padding: 2px !important;
  height: 40px;
  width: 120px;
  color: #175adb !important;
  font-size: 15px;
  border: 1px solid;
}
.chakra-menu__menuitem:focus {
  outline: none !important;
}

.otp-input-focus {
  outline: none;
  border-color: #185ADB;
}
.ant-select-selector { 
  border: 1px solid #e2e2e2 !important;
}
/* Increase specificity */
.ant-pagination-item-active.ant-pagination-item-active {
  border-color: #185ADB !important;
}

.ant-pagination-item-active.ant-pagination-item-active a {
  color: #185ADB !important;
}
.ql-editor {
  background-color: #fff !important;
}
.text-left {
  text-align: left;
  width: 100%;
}
.react-international-phone-input, .react-international-phone-input-container .react-international-phone-country-selector-button {
  height: 50px !important;
}
.react-international-phone-input {
  width: 300px;
}
@media (max-width: 767px) {
  .react-international-phone-input {
    width: 220px;
  }
}