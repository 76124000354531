.nav-wrapper{
    display: flex;
    flex-direction: row;
    width: 100%;
    position: fixed;
    height: 70px;
    max-width: 1420px;
    align-items: center;
    justify-content: space-between;
    padding: 0px 60px;
    background-color: white;
    z-index: 50;
    box-shadow: 2px 4px 10px #4645450f;
    @media (max-width: 420px) {
      padding: 0px 20px;
    }
}
.link-text{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 30px;
    color: #0A1931;
    cursor: pointer !important;
}
.auth-btn{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    // line-height: 30px;
    color: #fff;
    cursor: pointer !important;
}
.link-text:hover{
    color: #185ADB;
}

@media only screen and (min-width: 1280px) {
  .link-text{
    font-size: 16px;
  }
}