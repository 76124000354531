#productList {
    width: 100%;
    .result-wrapper {
        margin-top: 20px;
        display: flex;
        align-items:flex-end;
        justify-content: space-between;
        .sort-by-label {
            font-size: 12px;
        }
        .sort-filter {
            width: 200px;
        }
    }
    .page-title {
        text-align: left;
        width: 100%;
        font-size: 20px;
        font-weight: 600;
    }
    .product-list {
        width: 100%;
        .loader-center {
            width: 100%;
            display: flex;
            justify-content: center;
            position: absolute;
            bottom: 0;
        }
        .infinite-scroll {
            padding: 30px 0px 70px;
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
            gap: 16px;
            position: relative;
            .noMoreData {
                position: absolute;
                bottom: 0;
                display: flex;
                width: 100%;
                justify-content: center;
            }
        }
    }
}
.lockerEnd {
    display: flex;
    gap: 10px;
    align-items: center;
}