.main-wrapper {
    padding: 60px 20px;
    background-color: #f8f8f8;
    height: 100%;
    .content-container {
        max-width: 720px;
        margin: auto;
        text-align: center;
        .logo-wrapper {
            display: flex;
            justify-content: center;
            margin-bottom: 40px;
            img {
                width: 240px;
            }
        }
        h1 {
            font-size: 20px;
            font-weight: 800;
            line-height: 1.1;
            padding-bottom: 40px;
            margin: 0px;
            font-family: "poppins";
        }
    }
    
}