.wrapper{
    width: 100%;
    justify-content: center;
    background-color: #f8f8f8;
    .inner-wrapper{
        width: 100%;
        gap: 65px;
        flex-direction: column;
        align-items: center;
        @media (max-width: 768px) {
            gap: 60px;         
          }
    }
}
